.section {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  width: 100vw;
  margin: auto;
  background-color: #f0f0f0;
}

.home {
  /* background-color: rgba(240, 240, 240, 0.8); #f0f0f0 */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 2rem;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
}

.background {
  height: 100%;
  background-color: #f0f0f0;
  /* z-index: -3; */
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
}

/* .half-block {
  background-color: black;
  width: 40%;
  height: 100%;
} */

.beginning-title {
  position: absolute;
  top: 40%;
  /* left: 20%; */
  /* margin-top: 40%; */
  /* left: 30%; */
  width: fit-content;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-align: "center";
  font-weight: 150;
  background-color: red;
}

/* @media screen and (min-width: 900px) {
} */
