.mini-pic {
  width: 10%;
  margin-bottom: 3%;
}

.container {
  background-color: #f0f0f0;
  padding: 10px;
}

@media screen and (max-width: 575px) {
  .mini-pic {
    width: 30%;
  }
}
