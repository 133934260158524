/* * {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
a {
  margin: 0;
}
a {
  color: inherit;
} */

.link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  cursor: pointer;
  padding-top: 10px;
}
