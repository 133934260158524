.artwork {
  margin: 20px;
  width: 30vw;
  max-width: 300px;
  transition: scale 0.2s;
  align-self: center;
  filter: grayscale(100%);
}

.artwork:hover {
  scale: 1.1;
  filter: grayscale(0);
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-align: center;
  font-weight: 150;
  position: relative;
}

.gallery {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20;
  width: 80%;
  overflow-x: scroll;
  margin: auto;
}

.gallery::-webkit-scrollbar {
  display: none;
}

.gallery-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% auto;
}

#backBtn,
#nextBtn {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 40px;
  border-radius: 20px;
  background-color: rgb(221, 60, 87);
  border: none;
  transition: background-color 0.2s ease-in-out;
}

#backBtn:hover {
  background-color: rgba(39, 103, 46, 0.5);
  /* margin-right: 30px; */
}

#nextBtn:hover {
  background-color: rgba(39, 103, 46, 0.5);
  /* margin-left: 30px; */
}

.websites {
  padding: 20px;
  height: 40vh;
}
